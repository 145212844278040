import { useTranslation } from 'react-i18next'
import { ElevatedTitleCentered } from '../../../components/modules/ElevatedTitleCentered'

const anchorLinkSections = () => {
  const [t] = useTranslation()
  const data = [
    {
      title: t('CalculatorPageFinance.sidebarHeader', {
        defaultValue: 'Calculators',
      }),
      anchorLinks: [
        {
          to: '/calculators/personal-finance',
          name: t('CalculatorPageFinance.sidebarLink3', {
            defaultValue: 'Should I transfer my credit card balances?',
          }),
        },
        {
          to: '/calculators/personal-finance/credit-card-payoff',
          name: t('CalculatorPageFinance.sidebarLink4', {
            defaultValue: 'When will I pay off my credit card balances?',
          }),
        },
        {
          to: '/calculators/personal-finance/debt-consolidation',
          name: t('CalculatorPageFinance.sidebarLink2', {
            defaultValue: 'Should I consolidate my debt with a new loan?',
          }),
        },
        {
          to: '/calculators/personal-finance/student-loan-payoff',
          name: t('CalculatorPageFinance.sidebarLink8', {
            defaultValue: 'When will I pay off my student loans?',
          }),
        },
        {
          to: '/calculators/personal-finance/invest-or-pay-debt',
          name: t('CalculatorPageFinance.sidebarLink7', {
            defaultValue: 'Should I pay down debt or invest?',
          }),
        },
        {
          to: '/calculators/personal-finance/loan-payments',
          name: t('CalculatorPageFinance.sidebarLink1', {
            defaultValue: 'What would I pay for a personal loan?',
          }),
        },
        {
          to: '/calculators/personal-finance/net-worth',
          name: t('CalculatorPageFinance.sidebarLink5', {
            defaultValue: "What's my net worth?",
          }),
        },
        {
          to: '/calculators/personal-finance/cash-flow',
          name: t('CalculatorPageFinance.sidebarLink6', {
            defaultValue: "What's my cash flow?",
          }),
        },
      ],
    },
  ]

  return data
}

export { anchorLinkSections }
export default anchorLinkSections

const CrossSellTout = () => {
  const [t] = useTranslation()

  const richHeadline = {
    nodeType: 'document',
    data: {},
    content: [
      {
        nodeType: 'paragraph',
        content: [
          {
            nodeType: 'text',
            marks: [],
            value: t('CalculatorPageFinance.ctaHeadline', {
              defaultValue:
                'We can help you figure out your finances. Ask for a free consultation with a Financial Solutions Guide.',
            }),
            data: {},
          },
        ],
        data: {},
      },
    ],
  }

  return (
    <ElevatedTitleCentered
      richHeadline={richHeadline}
      buttonUrl="/contact-us"
      buttonCta={t('CalculatorPageFinance.ctaButton', {
        defaultValue: 'Learn more',
      })}
      buttonAlt={t('CalculatorPageFinance.ctaButtonAlt', {
        defaultValue: 'Learn more about personal finance by contacting us ',
      })}
    />
  )
}

export { CrossSellTout }
